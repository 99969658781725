<template>
  <div class="add-organization">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="140px"
      class="demo-ruleForm demo-ruleForm-invest"
      style="padding-top: 20px;box-sizing: border-box"
      v-loading="loading"
      >
      <el-form-item prop="image" label-width="80px">
        <SuperUpload
          :uploadCardAddress="uploadLogoAddress"
          :imgLimit="20"
          :cardplanList="imgList"
          @success="imgUploadSuccess"
          @CardPlanRemove="imgRemove"
          @handleExceed="handleExceed"
          :size="4194320"
          width="100%"
          height="100%"
        ></SuperUpload>
        <p class="tips">最多上传20张图片</p>
        <p class="tips">建议上传尺寸为4:3的图片</p>
        <p class="tips">仅支持jpg、jpeg、png格式大小不能大于4M</p>
      </el-form-item>
      <el-row>
        <el-col :span="10">
          <el-form-item label="机构名称/品牌名称" prop="name">
            <el-input v-model="ruleForm.name" maxlength="20" placeholder="请输入机构名称"></el-input>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input
              v-model="ruleForm.tel"
              type="number"
              placeholder="请输入电话号码"
              @change="phoneChange"
            ></el-input>
          </el-form-item>

          <el-form-item label="孵化器等级">
            <el-select
              v-model="ruleForm.category_level_id"
              clearable
              placeholder="请选择孵化器等级"
              style="width: 100%;"
            >
              <el-option v-for="item in levels" :key="item.id" :label="item.title" :value="item.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="公司全称">
            <el-input v-model="ruleForm.company_full_name" type="text" placeholder="公司全称" @input="fullNameChange($event)"></el-input>
          </el-form-item>
          <el-form-item label="行业领域:" class="organization_industry">
            <el-select
              v-model="ruleForm.start_id"
              multiple
              collapse-tags
							style="width: 100%"
              :disabled="disabled_start"
              placeholder="请选择">
              <el-option
                v-for="item in options_start"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="载体面积">
            <el-input v-model="ruleForm.measure" type="text" @change="measureChange" maxlength="8">
              <template slot="append">m²</template>
            </el-input>
          </el-form-item>
          <el-form-item label="房型" prop="houseTypes">
            <el-select
              multiple
              v-model="ruleForm.house_type_id"
              placeholder="请选择"
              style="width: 100%;"
            >
              <el-option
                v-for="item in houseTypes"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型" prop="park_type_id">
            <el-select
                    multiple
                    v-model="ruleForm.park_type_id"
                    placeholder="请选择"
                    style="width: 100%;"
            >
              <el-option
                      v-for="item in selectType"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="属性标签">
            <el-cascader
              v-model="ruleForm.attribute_id"
              :options="attributes"
              :props="{ multiple: true, checkStrictly: true ,value:'id',label:'title'}"
              clearable
              style="width:100%"
            ></el-cascader>
            <!-- <el-select
              multiple
              v-model="ruleForm.attribute_id"
              placeholder="请选择"
              style="width: 100%;"
            >
              <el-option
                v-for="item in attributes"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>-->
          </el-form-item>
          <el-form-item label="租金价格">
            <el-input v-model="ruleForm.rent_from" style="width:32%" type="text" @change="rent1">
              <template slot="append">￥</template>
            </el-input>
            <span style="margin:1.3%">—</span>
            <el-input v-model="ruleForm.rent_to" style="width:32%;margin-right:4%" type="text" @change="rent2">
              <template slot="append">￥</template>
            </el-input>
            <el-select v-model="ruleForm.rent_unit" placeholder="" style="width:80px">
              <el-option v-for="item in rentType" :label="item.title" :value="item.val" :key="item.val"></el-option>
            </el-select>          
          </el-form-item>
        </el-col>
        <el-col :span="10" :offset="2">
          <div class="position" style="display: flex;align-items: center;">
            <el-form-item label="地理位置">
              <el-select
                @change="provinceChange"
                v-model="ruleForm.province_id"
                clearable
                placeholder="省"
                style="width:120px;margin-right: 10px;"
              >
                <el-option
                  v-for="item in provinces"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="city_id" label-width="0">
              <el-select
                @change="cityChange"
                v-model="ruleForm.city_id"
                placeholder="市"
                clearable
                style="width:120px;margin-right: 10px;"
              >
                <el-option
                  v-for="item in cities"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="district_id" label-width="0">
              <el-select
                v-model="ruleForm.district_id"
                placeholder="区"
                clearable
                style="width:120px;margin-right: 8px;"
              >
                <el-option
                  v-for="item in districts"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="详细地址">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入具体位置"
              v-model="ruleForm.address"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="拥有种子资金数">
            <el-input placeholder="请输入种子资金数" v-model="ruleForm.total_seed_capital">
              <template slot="append">万元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="关于我们">
            <el-input
              :rows="4"
              type="textarea"
              placeholder="请输入内容"
              v-model="ruleForm.about_us"
              maxlength="1600"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="服务内容">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入内容"
              v-model="ruleForm.service_content"
              maxlength="1600"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item class="investSubmit">
        <el-button  type="primary" @click="submitForm('ruleForm')" size="medium" class="searchBtn">发布</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import SuperUpload from "@/components/Upload/SuperUpload.vue";
import { success, error, warning } from "@/utils/notification";
import * as validata from "@/utils/validata";
export default {
  name: "AddOrganization",
  components: { SuperUpload },
  data() {
    var checkSeedfund = (rule, value, callback) => {
      var newValue = String(value).split(".")[1];
      if (newValue && newValue.length > 2) {
        callback(new Error("只能输入两位小数"));
        return;
      } else {
        callback();
      }
    };
    return {
      id: Number(sessionStorage.organizationId),
      loading: true,
      ruleForm: {
        images: [],
        name: "",
        tel: "",
        province_id: [],
        city_id: [],
        district_id: [],
        address: "",
        industry_id: [],
        attribute_id: [],
        measure: "",
        rent_from: "",
        rent_to: "",
        rent_unit: 1,
        about_us: "",
        service_content: "",
        house_type_id: [],
        park_type_id: [],
        category_level_id: "",
        total_seed_capital: 0,
				start_id: []	//创投行业领域编号
      },
			options_start: [], //级联分类领域-创投
      disabled_start: false,

      provinces: [], //省
      cities: [], //市
      districts: [], //区
      uploadLogoAddress: this.URL.upLoad,
      imgList: [],
      houseTypes: [], //房源类型
      attributes: [], //属性标签
      industryLabels: [], //领域数据
      levels: [], //孵化器级别列表
      options: [], //级联分类领域
      rules: {
        //表单验证规则
        name: [
          { required: true, message: "机构名称不能为空", trigger: "blur" },
          { min: 1, max: 20, message: "长度 1 到 20 个字符", trigger: "blur" }
        ],
        province_id: [
          { required: true, message: "请选择省份", trigger: "change" }
        ],
        city_id: [{ required: true, message: "请选择城市", trigger: "change" }],
        district_id: [
          { required: true, message: "请选择区域", trigger: "change" }
        ],
        address: [
          { required: true, message: "地址不能为空", trigger: "blur" },
          { min: 1, max: 50, message: "长度 1 到50 个字符", trigger: "change" }
        ],
        tel: [
          { required: true, message: "请输入电话号码", trigger: "blur" },
          { min: 11, max: 11, message: "长度为11个字符", trigger: "change" }
        ],
        category_level_id: [
          { required: true, message: "请选择孵化器等级", trigger: "change" }
        ],
        total_seed_capital: [
          { required: true, message: "种子资金数不能为空！", trigger: "blur" },
          { validator: checkSeedfund, trigger: "change" }
        ]
      },
      selectType: [],
      rentType: [
        {title: '/小时', val: 1},
        {title: '/日', val: 2},
        {title: '/月', val: 3},
        {title: '/年', val: 4},
        {title: '/平/月', val: 6}
      ]
    };
  },
  inject: ["reload"],
  created() {
    this.getAttribute(); //获取属性标签
    this.publicArea(3246); //获取省市
    this.getCategories("starts");
    this.getHouse(); //获取房源类型
    this.getLevel(); //获取孵化器等级
    this.$http.get(this.URL.spaceTypes).then(r => { //获取类型
      this.selectType = r.data.data
      this.id && this.getOrgans(); //回显
    })
  },
  methods: {
    fullNameChange(e) {
      this.$forceUpdate()
    },
    // 图片上传成功
    imgUploadSuccess(response, filelist, file) {
      success("图片上传成功");
      if (filelist.length > 0) {
        filelist.map(v => {
          if (v.uid === file.uid) {
            v.path = response.data.path;
          }
        });
      }
      this.imgList = filelist;
    },
    // 图片移除
    imgRemove(filelist) {
      this.imgList = filelist;
    },
    // 图片提示
    handleExceed() {
      error("上传图片不能超过20张");
    },

    // 根据省选择城市
    publicArea(value) {
      this.$http
        .get(this.URL.getArea, {
          params: {
            city_id: value
          }
        })
        .then(res => {
          if (value == 3246) {
            this.provinces = res.data.data;
          }
          // else {
          //   this.cities = res.data.data;
          // }
        });
    },
    //城市数据
    provinceChange(value) {
      this.$http
        .get(this.URL.getArea, {
          params: {
            city_id: value
          }
        })
        .then(res => {
          this.cities = res.data.data;
        });
      this.ruleForm.city_id = "";
      this.ruleForm.district_id = "";
      this.districts = [];
    },
    //地区数据
    cityChange(value) {
      if (value != "") {
        this.$http
          .get(this.URL.getArea, {
            params: {
              city_id: value
            }
          })
          .then(res => {
            this.districts = res.data.data;
          });
        this.ruleForm.district_id = "";
      }
    },
    //编辑获取地区i
    getArea1(id) {
      this.$http
        .get(this.URL.getArea, {
          params: {
            city_id: id
          }
        })
        .then(res => {
          {
            this.cities = res.data.data;
          }
        });
    },
    getArea2(id) {
      this.$http
        .get(this.URL.getArea, {
          params: {
            city_id: id
          }
        })
        .then(res => {
          {
            this.districts = res.data.data;
          }
        });
    },
    // 浮点型 统一处理方法
    isNumber(val, data, text) {
      let show = validata.checkNumber(val);
      if (!show) {
        warning("请输入正确的" + text);
        this.ruleForm[data] = "";
      }
    },
    //分类 - 创投和国民行业列表
    async getCategories(url) {
      const res = await this.$http.get(this.URL.adminUrl[url], {});
			url == "economics"?this.options_economic = res.data.data:this.options_start = res.data.data
		},
    // 手机号码
    phoneChange(val) {
      let show = validata.checkPhoneNumber(val);
      if (!show) {
        this.ruleForm.tel = "";
      }
    },
    //面积数字验证
    measureChange(val) {
      this.isNumber(val, "measure", "载体面积");
    },

    //租金价格
    rent1(val) {
      this.isNumber(val, "rent_from", "租金价格");
    },
    rent2(val) {
      this.isNumber(val, "rent_to", "租金价格");
    },

    //机构列表详情(回显)
    getOrgans() {
      this.$http
        .get(this.URL.adminUrl.spaceList + this.id)
        .then(res => {
          const resData = res.data.data.organ;
          this.loading = false
          let images = resData.images;
          if (images != null) {
            images.map(v => {
              let obj = {};
              obj.uid = "";
              obj.url = v;
              obj.path = v;
              this.imgList.push(obj);
            });
          }
          this.ruleForm.company_full_name = resData.company_full_name
          for(let i in resData.startLabels){
            this.ruleForm.start_id.push(resData.startLabels[i][0])
          }
          this.ruleForm.tel = resData.tel;
          this.ruleForm.id = this.id;
          this.ruleForm.name = resData.name;
          this.ruleForm.address = resData.address;
          let arr = this.attributes.map(item => item.id);
          this.ruleForm.industry_id = resData.industry_labels;
          this.ruleForm.attribute_id = resData.attribute_labels;
          this.ruleForm.attribute_id = this.ruleForm.attribute_id.map(item => {
            if (arr.includes(item)) {
              item = [item];
            } else {
              item = [7, item];
            }
            return item;
          });
          this.ruleForm.measure = resData.measure;
          this.ruleForm.rent_from = resData.rent_from;
          this.ruleForm.rent_to = resData.rent_to;
          this.ruleForm.rent_unit = (resData.rent_unit == null)?1:resData.rent_unit;
          this.ruleForm.about_us = resData.about_us;
          this.ruleForm.service_content = resData.service_content;
          this.ruleForm.house_type_id = resData.house;
          this.ruleForm.park_type_id = resData.park;
          this.ruleForm.category_level_id = resData.category_level_id;
          this.ruleForm.total_seed_capital = resData.total_seed_capital;
          this.ruleForm.province_id = resData.province_id;
          this.ruleForm.city_id = resData.city_id;
          this.ruleForm.district_id = resData.district_id;
          this.getArea1(this.ruleForm.province_id);
          this.getArea2(this.ruleForm.city_id);
        });
    },

    //编辑机构列表
    submitForm(ruleForm) {
      let arr = [];
      if(!this.ruleForm.name){
        return false
      }
      this.ruleForm.attribute_id.length > 0 &&
      this.ruleForm.attribute_id.forEach(item => {
        item.length > 1 && item.splice(0, 1);
        arr.push(...item);
      });
      let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
      if(!reg.test(this.ruleForm.tel)){
        this.$message.error('手机号码错误，请重新填写');
        return false;
      }
      this.ruleForm.attribute_id = arr;
      this.ruleForm.measure = Number(this.ruleForm.measure);
      this.ruleForm.rent_from = Number(this.ruleForm.rent_from);
      this.ruleForm.rent_to = Number(this.ruleForm.rent_to);
      let imgList = this.imgList;
      if (imgList.length === 0) {
        error("至少上传一张图片");
        return false;
      }
      imgList.map(v => {
        this.ruleForm.images.push(v.path);
      });
      //表单总验证
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          this.$http
            .post(this.URL.adminUrl.spaceList + this.id, this.ruleForm)
            .then(res => {
              if (this.id && res.data.code == 200) {
                success("修改成功");
                this.reload()
              } else if (res.data.code == 200) {
                success("新增成功");
              }
            });
        } else {
          error("请检查必填项是否填写完整");
          return false;
        }
      });
    },
    //获取房源类型
    getHouse() {
      this.$http.get(this.URL.houseTypes, {}).then(res => {
        this.houseTypes = res.data.data;
      });
    },
    //获取属性标签
    getAttribute() {
      this.$http.get(this.URL.getAttribute, {}).then(res => {
        this.attributes = res.data.data;
        // if (this.id && this.ruleForm.industry_id.length > 0) {
        //   this.attributes = this.attributes.map(item => item.id);
        //   console.log(this.attributes,'this.attributes')
        //   this.ruleForm.industry_id = this.ruleForm.industry_id.map(item => {
        //     this.attributes.includes(item)
        //       ? (item = [item])
        //       : (item = [7, item]);
        //     return item;
        //   });
        // }
      });
    },
    //获取孵化器列表
    getLevel() {
      this.$http.get(this.URL.levels, {}).then(res => {
        this.levels = res.data.data;
      });
    }
  }
};
</script>
<style lang="less" scoped>
.add-organization {
  min-width: 1100px;
  .back-organization {
    // display:block;
    margin: 10px 0 15px 80px;
  }
  .position {
    .el-form-item {
      display: inline-block;
    }
  }
  .must_red::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
  .lh30 {
    line-height: 30px;
  }
  .tips {
    font-size: 12px;
    color: #ed1324;
    height: 24px;
  }
}
</style>
